import styled from 'styled-components/macro';

export const TimePickerStyle = styled.div`
  position: relative;
  color: var(--dark);
  width: 100%;
  border-radius: 60px;
  border: 1px solid var(--border-color);
  margin: 0 0 12px;

  @media (min-width: 992px) {
    width: ${({ $noWidth }) => ($noWidth ? 'auto' : '150px')};
  }

  .react-datepicker-wrapper {
    @media (max-width: 991px) {
      width: 100%;
    }
  }

  input {
    width: 100%;
    height: 50px;
    border: none;
    outline: none;
    background: var(--white);
    overflow: hidden;
    border-radius: 60px;
    color: var(--secondary-text-color);
    padding: 0px 10px 0px 15px;
    font-weight: 500;
    &::placeholder {
      color: var(--gray-5);
    }
  }
  .react-datepicker__time-list {
    li {
      height: auto !important;
      padding: 10px !important;
    }
  }
  .icon {
    font-size: 22px;
    line-height: 1;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    z-index: 2;
    pointer-events: none;
    .material-icons {
      font-size: inherit;
    }
  }
`;
