import { useEffect, useState } from 'react';
import { useCancellablePromise } from 'helpers/promiseHandler';
import { Fetch } from '../helpers/fetchWrapper';

const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};
const merchantToolService = {
  _url: `${process.env.REACT_APP_MERCHANT_TOOL_API}`,
  _token: process.env.REACT_APP_MERCHANT_TOOL_API_KEY,
  GetBusinessFees(mid) {
    const [fees, setFees] = useState([]);
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getBusinessFees(mid))
        .then(res => {
          setFees(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [mid]);
    return {
      fees_loading: status === STATUS.LOADING,
      fees_error: status === STATUS.ERROR ? status : '',
      fees_data: fees,
    };
  },
  async uploadTransactions(transactions) {
    const res = await Fetch.post(`${this._url}/upload-transaction`, transactions, this._token);
    if (res.status >= 200 && res.status < 300) {
      const data = await res.json();
      return data;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async uploadSettlements(settlements) {
    const res = await Fetch.post(`${this._url}/upload-settlements`, settlements, this._token);
    if (res.status >= 200 && res.status < 300) {
      const data = await res.json();
      return data;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async uploadSettlementFees(settlementFees) {
    const res = await Fetch.post(`${this._url}/upload-settlement-fees`, settlementFees, this._token);
    if (res.status >= 200 && res.status < 300) {
      const data = await res.json();
      return data;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async uploadFees(fees) {
    const res = await Fetch.post(`${this._url}/upload-fees`, fees, this._token);
    if (res.status >= 200 && res.status < 300) {
      const data = await res.json();
      return data;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async uploadMerchantFundingSummary(fundingSummary) {
    const res = await Fetch.post(`${this._url}/upload-funding-summary`, fundingSummary, this._token);
    if (res.status >= 200 && res.status < 300) {
      const data = await res.json();
      return data;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getBusinessFees(mid) {
    let res = await Fetch.get(`${this._url}/get-business-fees?mid=${mid}`, this._token);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getMerchantToolToken(_) {
    const res = await Fetch.post(`${this._url}/get-token`, _, this._token);
    if (res.status >= 200 && res.status < 300) {
      const data = await res.json();
      return data;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async remakeStatements({ year, month }) {
    const res = await Fetch.get(`${this._url}/generate-all-statements?year=${year}&month=${month}`, this._token);
    if (res.status >= 200 && res.status < 300) {
      const data = await res.json();
      return data;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
};
export default merchantToolService;
