import { useEffect, useState } from 'react';
import { Fetch } from '../helpers/fetchWrapper';
import { useCancellablePromise } from '../helpers/promiseHandler';

const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const businessService = {
  _url: `${process.env.REACT_APP_BUSINESS_API_URL}`,
  __token: process.env.REACT_APP_MERCHANT_TOOL_API_KEY,

  GetBusinesses(searchQuery, refetch) {
    const [businesses, setBusinesses] = useState({
      businesses: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getBusinesses(searchQuery))
        .then(res => {
          setBusinesses(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.searchText,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.filterStatus,
      searchQuery?.getDeleted,
      refetch,
    ]);
    return {
      businesses_loading: status === STATUS.LOADING,
      businesses_error: status === STATUS.ERROR ? status : '',
      businesses_data: businesses,
    };
  },

  GetBusinessDetails(id, refetch) {
    const [businessDetails, setBusinessDetails] = useState(null);
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getBusinessDetails(id))
        .then(res => {
          setBusinessDetails(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [refetch]);
    return {
      business_details_loading: status === STATUS.LOADING,
      business_details_error: status === STATUS.ERROR ? status : '',
      business_details_data: businessDetails?.business,
    };
  },

  GetIso(searchQuery, refetch) {
    const [iso, setIso] = useState({
      iso: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getIso(searchQuery))
        .then(res => {
          setIso(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [searchQuery?.page, searchQuery?.pageSize, searchQuery?.searchText, refetch]);
    return {
      iso_loading: status === STATUS.LOADING,
      iso_error: status === STATUS.ERROR ? status : '',
      iso_data: iso,
    };
  },
  GetIsoOptions(refetch) {
    const [isoOptions, setIsoOptions] = useState([]);
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getIsoOptions())
        .then(res => {
          setIsoOptions(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [refetch]);
    return {
      iso_options_loading: status === STATUS.LOADING,
      iso_options_error: status === STATUS.ERROR ? status : '',
      iso_options_data: isoOptions,
    };
  },

  async getBusinesses({
    page = 1,
    pageSize = 10,
    searchText = '',
    startDate = '',
    endDate = '',
    filterStatus = '',
    getDeleted = '',
  }) {
    let res = await Fetch.get(
      `${this._url}/get-all-business?page=${page}&itemsPerPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&status=${filterStatus}&getDeleted=${getDeleted}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return {
        businesses: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async createBusiness(payload) {
    let res = await Fetch.post(`${this._url}/create-business`, payload);

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async deleteBusiness(id, reason) {
    let res = await Fetch.post(`${this._url}/delete-business/${id}`, { reason });

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async editBusiness({ _id, ...rest }) {
    let res = await Fetch.put(`${this._url}/update-business/${_id}`, { _id, ...rest });

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getBusinessDetails(id) {
    let res = await Fetch.get(`${this._url}/get-business-details/${id}`);

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async changeBusinessStatus(id, payload) {
    let res = await Fetch.put(`${this._url}/change-business-status/${id}`, payload);

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async sendMerchantToolEmail(id) {
    let res = await Fetch.post(`${this._url}/send-mail-to-merchant-tool`, {
      businessId: id,
    });
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async uploadMerchants(payload) {
    let res = await Fetch.post(`${this._url}/upload-merchants`, payload);

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getIso({ searchText = '', page = 1, pageSize = 10 }) {
    let res = await Fetch.get(`${this._url}/get-iso?page=${page}&pageSize=${pageSize}&searchText=${searchText}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        iso: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async addIso(payload) {
    let res = await Fetch.post(`${this._url}/add-business-iso`, payload);

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateIso(payload, id) {
    let res = await Fetch.put(`${this._url}/update-business-iso/${id}`, payload);

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteIso(id) {
    let res = await Fetch.delete(`${this._url}/delete-business-iso/${id}`);

    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getAllBusinesses() {
    let res = await Fetch.get(`${this._url}/get-all-businesses`, null, {
      'x-api-key': this.__token,
    });
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res
        .filter(({ is_deleted }) => !is_deleted)
        .map(x => ({
          value: x._id,
          label: x.title,
        }));
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getIsoOptions() {
    let res = await Fetch.get(`${this._url}/iso-options`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
};

export default businessService;
