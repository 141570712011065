import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { TimePickerStyle } from './TimeSelect.styles';
import 'react-datepicker/dist/react-datepicker.css';

function TimePicker({ onChange, noWidth, placeholder = 'From', name, disabled = false, value }) {
  const [time, setTime] = useState('');
  useEffect(() => {
    if (value) {
      setTime(new Date(`2000-10-01 ${value}`));
    }
  }, []);

  function handelChange(t) {
    setTime(t);
    onChange({ target: { value: t, name } });
  }
  return (
    <TimePickerStyle $noWidth={noWidth} disabled={disabled}>
      <div className="icon">
        <span className="material-icons">watch_later</span>
      </div>
      <DatePicker
        disabled={disabled}
        selected={time}
        onChange={t => handelChange(t)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="hh:mm aa"
        placeholderText={placeholder}
      />
    </TimePickerStyle>
  );
}
export default TimePicker;
