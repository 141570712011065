import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  margin-top: 10px;
  overflow: hidden;
`;

export const ConfigList = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
`;
export const ConfigContent = styled.div`
  padding: 0 10px 10px 15px;
  flex-grow: 1;
  border-left: 2px solid var(--light);
  .btn-holder {
    display: flex;
    justify-content: flex-end;
  }
`;
export const ConfigHolder = styled.div`
  display: flex;
  gap: 20px;
  flex-flow: row-wrap;
`;
export const Column = styled.div`
  width: 100%;
  margin-bottom: 10px;
  padding: 0;
`;
export const ColumnWrap = styled.div`
  padding: 0px;
  width: 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
`;

export const FormHolder = styled.div`
  position: relative;
  margin: 0 0 15px;
  padding: 15px;
  border-radius: 10px;
  background: var(--light-secondary);
`;
export const Header = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
`;

export const FieldTitle = styled.strong`
  display: block;
  font-size: 20px;
  line-height: 23px;
  text-transform: capitalize;
  font-weight: 500;
  margin: 0 0 15px;
`;

export const CheckboxHolder = styled.span`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
export const FieldWithHolder = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
export const SecondSufix = styled.span`
  font-size: 12px;
  color: var(--light);
`;
export const FeildHeading = styled.span`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 1;
  margin-bottom: 10px;
  color: var(--secondary-text-color);
  font-size: var(--font-size-sm);
`;

export const GroupFieldHeading = styled.h4`
  margin: 0;
  line-height: 1;
  margin-bottom: 10px;
  font-size: var(--font-size-sm);
`;
export const InputWrap = styled.div`
  border: 2px solid #e6e8ec;
  padding: 15px 15px 0;
  border-radius: 5px;
`;

export const DayScheduleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f8f9fa;
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid #ddd;
  max-width: 300px;
  margin: 10px auto;
`;

export const TimeWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const TimeBox = styled.span`
  background: #e9ecef;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  color: #555;
`;
export const ScheduleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-radius: 8px;
  background: #f8f9fa;
  border: 1px solid #ddd;
  max-width: 500px;
  margin: 10px auto;
`;

export const DayLabel = styled.span`
  font-weight: bold;
  font-size: 16px;
  color: #333;
  flex: 1;
`;

export const TimeContainer = styled.div`
  display: flex;
  gap: 15px;
`;
