import React, { useEffect, useState } from 'react';
import Select from 'components/atoms/Select';
import businessService from 'services/businessService';
import Button from 'components/atoms/Button';
import { useContextHook } from 'use-context-hook';
import { AuthContext } from 'context/authContext';
import { useForm } from '../Form';
import Form from '../Form/Form';
import Field from '../Field';
import Toast from '../Toast';

// eslint-disable-next-line no-unused-vars
export default function ISOGroup({ onClose, data = null }) {
  const [form] = useForm();
  const [state, setState] = useState({
    businesses: [],
    title: '',
  });
  const [options, setOptions] = useState([]);
  const { refetch } = useContextHook(AuthContext, ['refetch', 'hasPermission']);

  const onSubmit = () => {
    if (data) {
      businessService
        .updateIso(
          {
            title: state.title,
            businesses: state.businesses.map(x => x.value),
          },
          data._id,
        )
        .then(() => {
          Toast({
            type: 'success',
            message: 'ISO updated successfully',
          });
          onClose();

          refetch();
        })
        .catch(err => {
          Toast({
            type: 'error',
            message: err?.message,
          });
        });
    } else {
      businessService
        .addIso({
          title: state.title,
          businesses: state.businesses.map(x => x.value),
        })
        .then(() => {
          Toast({
            type: 'success',
            message: 'ISO added successfully',
          });
          onClose();

          refetch();
        })
        .catch(err => {
          Toast({
            type: 'error',
            message: err?.message,
          });
        });
    }
  };

  useEffect(() => {
    businessService
      .getAllBusinesses()
      .then(res => {
        setOptions(res);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        title: data.title,
        businesses: data.businesses.map(x => ({
          label: x.title,
          value: x._id,
        })),
      });
    }
  }, [data]);

  return (
    <Form form={form} onTouched={e => setState({ ...state, ...e })} onSubmit={onSubmit}>
      <Form.Item name="title" label="Title" required>
        <Field />
      </Form.Item>
      <Form.Item name="businesses" label="Businesses" options={options} isMulti>
        <Select />
      </Form.Item>

      <Button type="primary" htmlType="submit">
        Save
      </Button>
    </Form>
  );
}
