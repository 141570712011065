import React, { useEffect, useMemo, useState } from 'react';
import TableLayout from 'components/atoms/TableLayout';
import Table from 'components/molecules/Table';
import transactionService from 'services/transactionService';
import Loaders from 'components/atoms/Loaders';
import { formatCurrency } from 'helpers/common';
import JsPDF from 'jspdf';
import domtoimage from 'dom-to-image';
import logo from '../../../assets/images/logo-dark.svg';
import userIcon from '../../../assets/images/userIcon.svg';
import number from '../../../assets/images/number.svg';
import time from '../../../assets/images/time.svg';
import { StatementStyled } from './StatementDetails.styles';
import 'styled-components/macro';

function StatementDetails({
  report_id,
  _: { type } = {},
  responsive = false,
  print,
  year = new Date().getMonth() === 0 ? new Date().getFullYear() - 1 : new Date().getFullYear(),
  month = new Date().getMonth() === 0 ? 12 : new Date().getMonth(),
  onClose,
}) {
  const { report_data, report_loading } = transactionService.GetReportDetails(report_id, year, month);
  const summaryColumnNames = [
    'Card Type',
    'Number of Sales',
    'Sales',
    'Number Of Credits',
    'Credits',
    'Total number of Items',
    'Average Tickets',
  ];
  const summary_rows = useMemo(
    () =>
      report_data?.cardTypeSummary?.map(summary => [
        summary.cardType,
        summary.numberOfSales,
        formatCurrency(Number(summary.sales)),
        summary.numberOfCredits,
        formatCurrency(Number(summary.credits)),
        summary.totalNumberOfItems,
        formatCurrency(Number(summary.averageTickets)),
      ]),
    [report_loading, report_data],
  );
  const funding_summary_col = [
    'Processing Date',
    'Card Type',
    'Transaction Amount',
    'Count',
    'Charges & Fees',
    'Net Transferred Amount',
  ];
  const [state, setState] = useState({
    page: 1,
    pageSize: 5,
  });
  async function downloadStatement(fileName) {
    const element = document.getElementById('dashboard-container');
    const options = {
      quality: 1,
      style: {
        transform: 'scale(1)',
        'transform-origin': 'top left',
      },
    };
    const dataUrl = await domtoimage.toPng(element, options);
    const pdf = new JsPDF({
      orientation: 'p',
      unit: 'pt',
      format: [
        element.clientHeight < element.clientWidth - 390 ? element.clientWidth - 390 : element.clientHeight,
        element.clientWidth - 390,
      ],
      compressPdf: true,
    });
    pdf.addImage({
      format: 'PNG',
      imageData: dataUrl,
      x: 0,
      y: 0,
      compression: 'FAST',
    });
    pdf.save(`${fileName}.pdf`);
    onClose();
  }

  useEffect(() => {
    if (responsive && Object.keys(report_data).length && report_data?.merchantDetails?.title) {
      setState({ page: 1, pageSize: report_data?.fundingSummary?.length });
      const fileName = `${report_data?.merchantDetails?.title}_${report_data?.billing_period}`;
      downloadStatement(fileName);
    }
  }, [report_loading]);

  useEffect(() => {
    if (state.page > 1) {
      setState({ ...state, pageSize: 20 });
    } else {
      setState({ ...state, pageSize: 5 });
    }
  }, [state.page]);
  // eslint-disable-next-line default-param-last
  const pagination = (data = [], { page, pageSize }) => {
    const start = (page - 1) * pageSize;
    const end = page * pageSize;
    return data.slice(start, end);
  };
  const funndingSummary = useMemo(
    () =>
      pagination(
        report_data?.fundingSummary?.map(_ => [
          _?.settlementDate?.split('T')?.[0],
          _?.type,
          _?.transactionAmount ? formatCurrency(Number(_?.transactionAmount)) : '',
          _.count,
          formatCurrency(Number(_?.chargesAndFees)),
          _?.netTransferredAmount ? formatCurrency(Number(_?.netTransferredAmount)) : '',
        ]),
        state,
      ),
    [report_data?.fundingSummary, state],
  );

  return (
    <Loaders loading={report_loading}>
      <StatementStyled id="dashboard-container">
        <div className="modal-head">
          <div className="logo-title-holder">
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
            <strong className="heading">
              {type === 'frequency_based' ? 'Monthly' : 'Custom Interval'} Merchant Statement Details!
            </strong>
          </div>
          <div className="info-col">
            <div className="column">
              <div className="col">
                <div className="image-holder">
                  <img src={userIcon} alt="Icon" />
                </div>
                <strong className="title">{report_data?.merchantDetails?.title ?? 'n/a'}</strong>
              </div>
              <div className="col">
                <div className="image-holder">
                  <img src={number} alt="Icon" />
                </div>
                <strong className="title">{report_data?.merchantDetails?.mid ?? 'n/a'}</strong>
              </div>
            </div>
            <div className="column">
              <div className="col">
                <div className="image-holder">
                  <img src={time} alt="Icon" />
                </div>
                <strong className="title">Billing period: {report_data?.billing_period}.</strong>
              </div>
            </div>
          </div>
        </div>
        <div className="paragraph">
          <p>
            If you have any questions regarding your statement, please contact: 1-800-848-2974 or e-mail:
            merchantservices@pspservicesco.com.Any discrepancies must be brought to our attention within 60 days from
            the date of statement. All amounts in Canadian Dollars. Rounding differences may occur during calculation of
            fees. EDMR = Effective Merchant Discount Rate, For more information on interchange or the interchange fee
            descriptors, please visit <a href="https://www.pspservicesco.com">www.pspservicesco.com</a>
          </p>
        </div>
        {state.page === 1 && (
          <div className="table-wrap">
            <strong className="tables-heading">Card Type Summary</strong>
            <span
              css={`
                font-size: 1.2rem;
                font-weight: 600;
                margin-left: 20px;
              `}>
              Card Type Summary Total:{' '}
              {formatCurrency(report_data?.cardTypeSummary?.reduce((acc, _) => acc + Number(_.sales), 0).toFixed(2))}
            </span>
            <TableLayout isSearchBar={false} currentPage={15}>
              <Table
                center
                responsive={!responsive}
                noPadding
                rowsData={summary_rows}
                columnNames={summaryColumnNames}
              />
            </TableLayout>
          </div>
        )}

        {state.page === 1 && (
          <div className="table-wrap">
            <strong className="tables-heading">Card Type Charges</strong>
            <span
              css={`
                font-size: 1.2rem;
                font-weight: 600;
                margin-left: 20px;
              `}>
              Card Type Charge Fees Total:{' '}
              {formatCurrency(
                report_data?.cardTypeCharges
                  ?.reduce((acc, _) => acc + _.data.reduce((accc, __) => accc + __.totalCharged, 0), 0)
                  .toFixed(2),
              )}
            </span>
            {report_data?.cardTypeCharges?.length ? (
              report_data?.cardTypeCharges?.map(cardTypeCharge => (
                <TableLayout isSearchBar={false} heading={cardTypeCharge._id} currentPage={15} noPadding>
                  <Table
                    sameWidth
                    center
                    responsive={!responsive}
                    noPadding
                    rowsData={cardTypeCharge.data.map(_ => [
                      _?.TRANSACTION_TYPE_DESC,
                      `${_?.perTransactionType === 'FIXED' ? '$' : ''} ${_?.perTranCharged?.toFixed(2)}  ${
                        _?.perTransactionType !== 'FIXED' ? '%' : ''
                      } `,
                      formatCurrency(_?.amount?.toFixed(2)),
                      _?.items,
                      formatCurrency(_?.totalCharged?.toFixed(2)),
                    ])}
                    columnNames={['Card Type', 'Per Tran', 'Amount', 'Items', 'Total Charged']}
                    customWidth
                  />
                </TableLayout>
              ))
            ) : (
              <TableLayout isSearchBar={false} currentPage={15} noPadding>
                <Table center noPadding rowsData={[]} columnNames={[]} customWidth responsive={!responsive} />
              </TableLayout>
            )}
          </div>
        )}

        {state.page === 1 && (
          <div className="table-wrap">
            <strong className="tables-heading">Other Fees & Charges</strong>
            <span
              css={`
                font-size: 1.2rem;
                font-weight: 600;
                margin-left: 20px;
              `}>
              Other Fees and Charges Fees Total:{' '}
              {formatCurrency(report_data?.otherFees?.reduce((acc, _) => acc + Number(_.amount), 0).toFixed(2))}
            </span>
            <TableLayout isSearchBar={false} currentPage={15}>
              <Table
                responsive={!responsive}
                noPadding
                rowsData={report_data?.otherFees?.map(_ => [
                  _?.type,
                  formatCurrency(Number(_?.charge)),
                  _.count,
                  formatCurrency(Number(_?.amount)),
                ])}
                columnNames={['Fee Name', 'Charge', 'Count', 'Amount']}
              />
            </TableLayout>
          </div>
        )}

        <div className="table-wrap">
          <strong className="tables-heading">Funding Summary</strong>
          <TableLayout
            hideItemsPerPage
            print={print}
            isSearchBar={false}
            currentPage={state.page}
            noPadding
            pageSize={state.pageSize}
            totalCount={report_data?.fundingSummary?.length}
            onChangeFilters={_ => setState(__ => ({ ...__, ..._ }))}>
            <Table
              center
              responsive={!responsive}
              noPadding
              rowsData={funndingSummary}
              columnNames={funding_summary_col}
              customWidth
            />
          </TableLayout>
        </div>
      </StatementStyled>
    </Loaders>
  );
}

export default StatementDetails;
