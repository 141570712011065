import 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { LoadingContext } from 'context/loadingContext';
import Header from 'components/molecules/Header';
import Button from 'components/atoms/Button';
import topNavData from 'nav.json';
import { useParams } from 'react-router-dom';
import ModalContainer from 'components/molecules/ModalContainer';
import { AuthContext } from 'context/authContext';
import PermissionForm from 'components/organisms/PermissionsForm';
import adminService from 'services/adminService';
import Toast from 'components/molecules/Toast';
import RolesForm from 'components/organisms/RolesForm';
import AdminForm from 'components/organisms/AdminForm';
import TopBarSearch from 'components/molecules/TopBarSearch';
import ConfigurationTemplateForm from 'components/organisms/ConfigurationTemplateForm';
import { useContextHook } from 'use-context-hook';
import CertificateForm from 'components/organisms/CertificateForm';
import TagTemplatesForm from 'components/organisms/TagTemplatesForm';
import TagFrom from 'components/organisms/TagForm';
import TagList from 'components/organisms/TagList';
import ISOGroup from 'components/molecules/ISOGroup';

function TopBar({ merged }) {
  const { isLoading } = useContextHook(LoadingContext, ['isLoading']);
  const { hasPermission } = useContextHook(AuthContext, ['hasPermission']);

  const { view: title } = useParams();

  const { buttons = [] } = topNavData.find(({ file }) => file === title);

  const restorePermissions = async () => {
    try {
      const res = await adminService.restorePermissions();
      if (res) {
        Toast({
          type: 'success',
          message: 'Permissions restored successfully',
        });
      }
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex?.message,
      });
    }
  };

  return merged ? (
    <Header title="dashboard">{hasPermission('dashboard.top-search') ? <TopBarSearch /> : null}</Header>
  ) : (
    <Header title={title.split('-').join(' ')}>
      {isLoading ? (
        <Skeleton rectangle height={40} width={131} css="border-radius:8px !important;" />
      ) : (
        <>
          {buttons.includes('admins.create') && hasPermission('admins.create') && (
            <ModalContainer
              lg
              title="Create Admin"
              btnComponent={({ onClick }) => (
                <Button
                  type="primary"
                  onClick={onClick}
                  iconMobile
                  prefix={<i className="material-icons-outlined">add</i>}>
                  <span className="text">Create Admin</span>
                </Button>
              )}
              content={({ onClose }) => <AdminForm onClose={onClose} />}
            />
          )}

          {buttons.includes('permissions.create') && hasPermission('permissions.create') && (
            <ModalContainer
              lg
              title="Create Permission"
              btnComponent={({ onClick }) => (
                <Button
                  type="primary"
                  onClick={onClick}
                  iconMobile
                  prefix={<i className="material-icons-outlined">add</i>}>
                  <span className="text">Create Permission</span>
                </Button>
              )}
              content={({ onClose }) => <PermissionForm onClose={onClose} />}
            />
          )}

          {buttons.includes('permissions.restore') && hasPermission('permissions.restore') && (
            <Button
              type="outline"
              onClick={restorePermissions}
              iconMobile
              prefix={<i className="material-icons-outlined">restore</i>}>
              <span className="text">Restore Permissions</span>
            </Button>
          )}

          {buttons.includes('certificate.create') && hasPermission('certificate.create') && (
            <ModalContainer
              lg
              title="Inject Certificate"
              btnComponent={({ onClick }) => (
                <Button
                  type="primary"
                  onClick={onClick}
                  iconMobile
                  prefix={<i className="material-icons-outlined">add</i>}>
                  <span className="text">Inject Certificate</span>
                </Button>
              )}
              content={({ onClose }) => <CertificateForm onClose={onClose} />}
            />
          )}

          {buttons.includes('roles.create') && hasPermission('roles.create') && (
            <ModalContainer
              lg
              title="Create Role"
              btnComponent={({ onClick }) => (
                <Button
                  type="primary"
                  onClick={onClick}
                  iconMobile
                  prefix={<i className="material-icons-outlined">add</i>}>
                  <span className="text">Create Role</span>
                </Button>
              )}
              content={({ onClose }) => <RolesForm onClose={onClose} />}
            />
          )}

          {buttons.includes('configuration-templates.create') && hasPermission('configuration-templates.create') && (
            <ModalContainer
              lg
              title="Create Configuration Template"
              btnComponent={({ onClick }) => (
                <Button
                  type="primary"
                  onClick={onClick}
                  iconMobile
                  prefix={<i className="material-icons-outlined">add</i>}>
                  <span className="text">Create Configuration Template</span>
                </Button>
              )}
              content={({ onClose }) => <ConfigurationTemplateForm closeModal={onClose} />}
            />
          )}

          {buttons.includes('tag-templates.list-tags') && (
            <ModalContainer
              width={1100}
              title="Tags List"
              btnComponent={({ onClick }) => (
                <Button type="primary" onClick={onClick} iconMobile>
                  <span className="text">List Tags</span>
                </Button>
              )}
              content={({ onClose }) => <TagList closeModal={onClose} />}
            />
          )}

          {buttons.includes('tag-templates.create-tag') && hasPermission('tag-templates.create-tag') && (
            <ModalContainer
              lg
              title="Create Tag"
              btnComponent={({ onClick }) => (
                <Button
                  type="primary"
                  onClick={onClick}
                  iconMobile
                  prefix={<i className="material-icons-outlined">add</i>}>
                  <span className="text">Create Tag</span>
                </Button>
              )}
              content={({ onClose }) => <TagFrom closeModal={onClose} />}
            />
          )}
          {buttons.includes('tag-templates.create') && hasPermission('tag-templates.create') && (
            <ModalContainer
              lg
              title="Create Tag Template"
              btnComponent={({ onClick }) => (
                <Button
                  type="primary"
                  onClick={onClick}
                  iconMobile
                  prefix={<i className="material-icons-outlined">add</i>}>
                  <span className="text">Create Tag Template</span>
                </Button>
              )}
              content={({ onClose }) => <TagTemplatesForm closeModal={onClose} />}
            />
          )}
          {buttons.includes('iso.add') && hasPermission('iso.add') && (
            <ModalContainer
              lg
              title="Create ISO Group"
              btnComponent={({ onClick }) => (
                <Button
                  type="primary"
                  onClick={onClick}
                  iconMobile
                  prefix={<i className="material-icons-outlined">add</i>}>
                  <span className="text">Create ISO</span>
                </Button>
              )}
              content={({ onClose }) => <ISOGroup onClose={onClose} />}
            />
          )}
        </>
      )}
    </Header>
  );
}

export default TopBar;
